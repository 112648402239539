<template>
  <PageContent :sectionStyles="styles.pageContentSection">
    <div class="box tile is-vertical is-12">
      <div class="level">
        <span class="title pt-2 pl-2">User Settings</span>
      </div>

      <LinksMenu
        aria-label="account settings tabs"
        :tabs="tabs"
        prefix="userSettings."
        :styles="{
          paddingLeft: '0'
        }"
      >
        <section :style="{ overflowX: 'auto', flexGrow: 2 }">
          <SlideTransition :name="transitionName">
            <keep-alive>
              <router-view :key="$route.name" />
            </keep-alive>
          </SlideTransition>
        </section>
      </LinksMenu>
    </div>
  </PageContent>
</template>

<script>
import { ToastProgrammatic as Toast } from 'buefy'
import _get from 'lodash/get'
import moment from 'moment'
import { mapState, mapGetters, mapActions } from 'vuex'
//
import PageContent from '@/containers/PageContent'
import kms from '@/services/kms'
import SlideTransition from '@/components/transitions/Slide.vue'
import LinksMenu from '@/components/menus/Links'

function notifyError(e) {
  Toast.open({
    duration: 7000,
    message: e.message || e,
    position: 'is-bottom',
    type: 'is-danger'
  })
}

function notifyMessage(e) {
  Toast.open({
    duration: 7000,
    message: e.message || e,
    position: 'is-bottom',
    type: 'is-success'
  })
}

let DEFAULT_FORM_VALUES = {
  fullName: '',
  emailAddress: '',
  phone: ''
}

export default {
  components: {
    PageContent,
    SlideTransition,
    LinksMenu
  },
  data: function() {
    return {
      myHtml: "<span id='supportTab'>Support Access</span>",
      formComplete: false,
      formData: {
        ...DEFAULT_FORM_VALUES
      },
      toggle: false,
      loading: true,
      isDebug: false,
      profile: null,
      hoaList: [{}],
      formatDateFn: function(value) {
        return value && value != undefined
          ? moment(value.toString(), 'YYYY/MM/DD HH:mm:ss')
              .format('MM-DD-YYYY')
              .toString()
          : ''
      },
      styles: {
        pageContentSection: {
          width: '100%'
        },
        mainContainer: {
          display: 'flex'
        },
        content: {
          margin: '0 auto',
          width: 'calc(100% - 160px)',
          overflowX: 'auto'
        },
        menu: {
          width: '260px',
          paddingLeft: '20px'
        }
      },
      tabs: [
        {
          label: 'Profile',
          name: 'profile'
        },
        {
          label: 'Account Security',
          name: 'accountsecurity'
        },
        {
          label: 'HOA Accounts',
          name: 'hoaaccounts'
        },
        {
          label: 'Notification Subscriptions',
          name: 'alertsubscriptions'
        },
        {
          label: 'Additional Authentication',
          name: 'twofactorauthentication'
        }
      ],
      transitionName: 'slide-up'
    }
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser', 'retrieveUser'])
    })
  },

  mounted() {
    this.loading = true
    this.currentAssociation = this.hoaId
    this.reload()

    this.loading = false
  },

  watch: {
    hoaId() {
      this.reload()
    },
    authUser() {
      // reset association when user status changes
      this.currentAssociation = this.hoaId
    },

    currentAssociation(hoaId) {
      // pass selected HOA ID to Vuex when user changes it here
      this.selectHoaId(hoaId)
    },

    toggle() {
      if (this.toggle == false) {
        this.reload()
        this.toggle = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
      }
    },

    $route: function(to, from) {
      const toIndex = _get(to, ['params', 'index'], 1)
      const fromIndex = _get(from, ['params', 'index'], 1)

      this.transitionName = toIndex < fromIndex ? 'slide-up' : 'slide-down'
    }
  },

  methods: {
    ...mapActions('user', ['selectHoaId', 'userRetrieve']),

    async reload() {
      this.loading = true

      this.refreshUserProfile()

      this.refreshHoaList()

      this.formData = { ...DEFAULT_FORM_VALUES }
      if (this.$refs.form) {
        this.$refs.form.reset()
      }

      this.loading = false
    },

    async refreshUserProfile() {
      try {
        const refreshedUser = await kms.get('/User')

        if (this.isDebug == true) console.debug('refreshedUser=' + JSON.stringify(refreshedUser))

        if (refreshedUser && refreshedUser != undefined) {
          this.profile = refreshedUser
          this.formData.fullName = this.profile.name
          this.formData.emailAddress = this.profile.email
          this.formData.phone = this.profile.phoneNumber
          if (this.isDebug == true) console.debug('this.profile=' + JSON.stringify(this.profile))
        } else {
          if (this.isDebug == true) console.debug('else this.profile')
        }
      } catch (e) {
        notifyError(e)
      }
    },

    async refreshHoaList() {
      try {
        const refreshedHoaList = await kms.get(
          '/Hoa/List?showDeletedAccountManagerAssociations=false'
        )

        if (this.isDebug == true) console.debug('refreshedUser=' + JSON.stringify(refreshedHoaList))

        if (refreshedHoaList && refreshedHoaList != undefined) {
          this.hoaList = refreshedHoaList
          if (this.isDebug == true) console.debug('this.hoaList=' + JSON.stringify(this.hoaList))
        } else {
          if (this.isDebug == true) console.debug('else this.hoaList')
        }
      } catch (e) {
        notifyError(e)
      }
    },

    setChangePasswordModal() {
      this.toggle = true
    },

    getProfileFormPayload() {
      const payload = {
        name: this.formData.fullName,
        email: this.formData.emailAddress,
        phoneNumber: this.formData.phone
      }

      return payload
    },
    async leaveHoa(leaveHoaID, hoaName) {
      if (!this.authUser || this.authUser == undefined || !leaveHoaID || leaveHoaID == undefined) {
        notifyError('There was a problem leaving this Hoa.')
        return
      }

      const leavePayload = {
        accountManagingUserID: this.authUser.accountManagingUserID,
        accountID: this.authUser.accountID,
        hoaID: leaveHoaID
      }

      try {
        const path = `/User/LeaveHoa?accountManagingUserID=${leavePayload.accountManagingUserID}&accountID=${leavePayload.accountID}&hoaID=${leavePayload.hoaID}`
        const results = await kms.post(path, leavePayload)

        if (this.isDebug == true) console.debug(JSON.stringify(results))

        if (results && results != undefined) {
          if (results == true) {
            notifyMessage(`Successfully left ${hoaName}`)
            this.reload()
          } else {
            notifyError(`There was a problem leaving this Hoa.`)
          }
        } else {
          notifyError('There was a problem leaing this Hoa.')
        }
        this.loading = false
      } catch (e) {
        notifyError(e)
        this.loading = false
      }
    },
    async onProfileFormSubmit() {
      try {
        if (!this.formData || this.formData == undefined) {
          notifyError('There was a problem saving your user profile.')
          return
        }

        this.loading = true
        console.debug('payload formData= ' + JSON.stringify(this.formData))
        const returnPayload = this.getProfileFormPayload()
        console.debug('payload= ' + JSON.stringify(returnPayload))

        try {
          const path = `/User/SaveIdentityUserProfile`
          const results = await kms.post(path, returnPayload)

          if (this.isDebug == true) console.debug(JSON.stringify(results))

          if (results && results != undefined) {
            if (results == true) {
              notifyMessage(`Your user profile has been updated`)
              this.reload()
            } else {
              notifyError(`There was a problem updating your user profile`)
            }
          } else {
            notifyError('There was a problem updating the detailed description.')
          }
          this.loading = false
        } catch (e) {
          notifyError(e)
        }
        this.loading = false
      } catch (e) {
        notifyError(e)
      }

      this.loading = false
    }
  },

  i18n: {
    messages: {
      en: { userSettings: { title: 'User Settings' } }
    }
  }
}
</script>

<style lang="scss">
textarea {
  min-width: 600px !important;
}

button {
  padding: 5px;
}

.account-settings-tabs > section {
  width: calc(100% - 200px);
  float: right;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 60px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > div {
    width: 940px;
  }

  .app-properties {
    > div {
      margin: 0 8px 8px 0;
      font-style: normal !important;

      > label {
        display: block;
      }
      > span {
        font-style: normal !important;
      }
      .vue-quick-edit {
        display: inline-block;
        margin-left: 8px;
        font-style: normal !important;
      }
    }
  }
}
</style>
